import { createRouter, createWebHashHistory } from 'vue-router'
//import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Search',
    component: () => import('../views/search.vue')
  },
  {
    path: '/market_info',
    name: 'market_info',
    component: () => import('../views/MarketInfo.vue')
  },
  {
    path: '/lease_info',
    name: 'LeaseInfo',
    component: () => import('../views/LeaseInfo.vue')
  },
  {
    path: '/build_info',
    name: 'BuildInfo',
    component: () => import('../views/BuildInfo.vue')
  },
  {
    path: '/corp_list_group',
    name: 'CorpListGroup',
    component: () => import('../views/CorpListGroup.vue')
  },
  {
    path: '/corp_list_group_space',
    name: 'CorpListGroupSpace',
    component: () => import('../views/CorpListGroupSpace.vue')
  },
  {
    path: '/stock_company_info',
    name: 'stock_company_info',
    component: () => import('../views/StockCompanyInfo.vue')
  },
  {
    path: '/stock_company_list',
    name: 'stock_company_list',
    component: () => import('../views/CorpListGroupStockCompanyList.vue')
  },
  
  {
    path: '/search_result',
    name: 'SearchResult',
    component: () => import('../views/search_result.vue')
  },
  {
    path: '/dragmap',
    name: 'dragmap',
    component: () => import('../views/dragmap.vue')
  },
  {
    path: '/movingCases',
    name: 'movingCases',
    component: () => import('../views/movingCases.vue')
  },
  {
    path: '/movingCasesList',
    name: 'movingCasesList',
    component: () => import('../views/movingCasesList.vue')
  },
  {
    path: '/movingCasesInfo',
    name: 'movingCasesInfo',
    component: () => import('../views/movingCasesInfo.vue')
  },
  {
    path: '/move_plan',
    name: 'move_plan',
    component: () => import('../views/move_plan.vue')
  },
  {
    path: '/gmap',
    name: 'gmap',
    component: () => import('../views/gmap.vue')
  },
  {
    path: '/gmap_street',
    name: 'gmap_street',
    component: () => import('../views/gmap_street.vue')
  },
  {
    path: '/corpRecommend',
    name: 'corpRecommend',
    component: () => import('../views/CorpRecommend.vue')
  },
  {
    path: '/gmap_point',
    name: 'gmap_point',
    component: () => import('../views/gmap_point.vue')
  },
  {
    path: '/corptop100',
    name: 'corptop100',
    component: () => import('../views/Corptop100.vue')
  },
  {
    path: '/corphot100',
    name: 'corphot100',
    component: () => import('../views/Corphot100.vue')
  },
  {
    path: '/corp1',
    name: 'CorpList1',
    component: () => import('../views/CorpList1.vue')
  },
  {
    path: '/corp1/:dataId',
    name: 'CorpArticle1',
    component: () => import('../views/CorpArticle1.vue')
  },
  {
    path: '/corp2',
    name: 'CorpList2',
    component: () => import('../views/CorpList2.vue')
  },
  {
    path: '/corp2/:dataId',
    name: 'CorpArticle2',
    component: () => import('../views/CorpArticle1.vue')
  },
  {
    path: '/corp3',
    name: 'CorpList3',
    component: () => import('../views/CorpList3.vue')
  },
  {
    path: '/corp3/:dataId',
    name: 'CorpArticle3',
    component: () => import('../views/CorpArticle1.vue')
  },
  {
    path: '/corp4',
    name: 'CorpList4',
    component: () => import('../views/CorpList4.vue')
  },
  {
    path: '/corp4/:dataId',
    name: 'CorpArticle4',
    component: () => import('../views/CorpArticle1.vue')
  },
  {
    path: '/corp5',
    name: 'CorpList5',
    component: () => import('../views/CorpList5.vue')
  },
  {
    path: '/corp5/:dataId',
    name: 'CorpArticle5',
    component: () => import('../views/CorpArticle1.vue')
  },
  {
    path: '/corp6',
    name: 'CorpList6',
    component: () => import('../views/CorpList6.vue')
  },
  {
    path: '/corp6/:dataId',
    name: 'CorpArticle6',
    component: () => import('../views/CorpArticle1.vue')
  },
  {
    path: '/corp7',
    name: 'CorpList7',
    component: () => import('../views/CorpList7.vue')
  },
  {
    path: '/corp7/:dataId',
    name: 'CorpArticle7',
    component: () => import('../views/CorpArticle1.vue')
  },
  {
    path: '/corp8',
    name: 'CorpList8',
    component: () => import('../views/CorpList8.vue')
  },
  {
    path: '/corp8/:dataId',
    name: 'CorpArticle8',
    component: () => import('../views/CorpArticle1.vue')
  },
  {
    path: '/corp9',
    name: 'CorpList9',
    component: () => import('../views/CorpList9.vue')
  },
  {
    path: '/corp9/:dataId',
    name: 'CorpArticle9',
    component: () => import('../views/CorpArticle1.vue')
  },
  {
    path: '/corp10',
    name: 'CorpList10',
    component: () => import('../views/CorpList10.vue')
  },
  {
    path: '/corp10/:dataId',
    name: 'CorpArticle10',
    component: () => import('../views/CorpArticle1.vue')
  },
  {
    path: '/build1',
    name: 'BuildList1',
    component: () => import('../views/BuildList1.vue')
  },
  {
    path: '/build1/:dataId',
    name: 'BuildArticle1',
    component: () => import('../views/BuildArticle1.vue')
  },
  {
    path: '/build2',
    name: 'BuildList2',
    component: () => import('../views/BuildList2.vue')
  },
  {
    path: '/build2/:dataId',
    name: 'BuildArticle2',
    component: () => import('../views/BuildArticle2.vue')
  },
  {
    path: '/build3',
    name: 'BuildList3',
    component: () => import('../views/BuildList3.vue')
  },
  {
    path: '/build3/:dataId',
    name: 'BuildArticle3',
    component: () => import('../views/BuildArticle3.vue')
  },
  {
    path: '/build4/:dataId',
    name: 'BuildArticle4',
    component: () => import('../views/BuildArticle4.vue')
  }, 
  {
    path: '/corpReference',
    name: 'CorpReference',
    component: () => import('../views/CorpReference.vue')
  },
  {
    path: '/corpReference/:dataId',
    name: 'CorpReferenceArticle',
    component: () => import('../views/CorpArticle1.vue')
  },
  {
    path: '/custom_needs',
    name: 'custom_needs',
    component: () => import('../views/customNeeds.vue')
  },
  {
    path: '/notification',
    name: 'Notification',
    component: () => import('../views/Notification.vue')
  },
  {
    path: '/notification_list',
    name: 'NotificationList',
    component: () => import('../views/NotificationList.vue')
  },
  {
    path: '/search_building',
    name: 'search_building',
    component: () => import('../views/search_building.vue')
  },
  {
    path: '/report',
    name: 'ReportList',
    component: () => import('../views/ReportList.vue')
  },
  {
    path: '/report/add',
    name: 'ReportAdd',
    component: () => import('../views/ReportArticle.vue')
  },
  {
    path: '/report/edit/:dataId',
    name: 'ReportEdit',
    component: () => import('../views/ReportArticle.vue')
  },
  {
    path: '/report/build_info',
    name: 'ReportBuildInfo',
    component: () => import('../views/ReportBuildInfo.vue')
  },
  {
    path: '/report/corp_neet_info',
    name: 'CorpNeetInfo',
    component: () => import('../views/CorpNeetInfo.vue')
  },
  {
    path: '/corp_need',
    name: 'corp_need',
    component: () => import('../views/CorpNeedList.vue')
  },
  {
    path: '/corp_need/edit',
    name: 'corp_need_edit',
    component: () => import('../views/CorpNeedAdd.vue')
  },
  {
    path: '/corp_need_info',
    name: 'corp_need_info',
    component: () => import('../views/CorpNeedInfoList.vue')
  },
  {
    path: '/corp_need_info/edit',
    name: 'corp_need_info_edit',
    component: () => import('../views/CorpNeedInfoAdd.vue')
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router

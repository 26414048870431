<template>
  <router-view v-slot="{Component}">
    <transition name="fade" mode="out-in">
      <component :is="Component" />
    </transition>
  </router-view>
</template>

<script>
export default {
  name: "App",
};
</script>

<style lang="scss">
body {
  background: none !important;
  select {
    max-width: 150px;
  }
}
.btn-black{
  background-color: #003f2d;
  color: #fff;
}

.table-responsive{
  margin-bottom: 20px !important;
}

#wrapper {
  position: relative;
}
.lang {
  display: inline-block;
}
.recommend td {
  background: #003f2d !important;
  color: #fff;
}

.custom-input {
  position: relative;
  width: 16px;
  height: 16px;
  display: inline-block;
}

.custom-input input:checked + .show-box {
  background: #012a2d;
  /* 这里是背景颜色，可以自定义设置 */
}

.custom-input .show-box {
  position: absolute;
  top: 0;
  left: 0;
  width: 16px;
  height: 16px;
  z-index: 1;
  border-radius: 2px;
  border: 2px solid #012a2d;
  /* 这里是对勾颜色，可以自定义，和勾选框背景色色差较大 */
  background: white;
}
.custom-input .radio {
  border-radius: 16px;
}
.custom-input .show-box:before {
  content: "";
  position: absolute;
  top: 2px;
  left: 4px;
  width: 5px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.custom-input .radio:before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 12px;
  height: 12px;
  border: solid white;
  border-width: 2px;
  border-radius: 10px;
}
.custom-input input {
  opacity: 0;
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
}
</style>